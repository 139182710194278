import { Link } from "gatsby"
import React from "react"
import { useState } from "react"

const Logo = props => {
  const [colors, setColors] = useState({ black: "#000", white: "#fff" })
  return (
    <Link class={props.class} to="/">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.43 54.54">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="bottom_text" data-name="bottom text">
              <path
                style={{ fill: "#ee4266" }}
                class="cls-1"
                d="M15.77 52.94l.6-1a7.86 7.86 0 004.75 1.49c2.69 0 3.86-1 3.86-2.17 0-3.35-8.86-1.29-8.86-5.82 0-1.8 1.65-3.35 5.31-3.35a9.25 9.25 0 014.46 1.08l-.52 1a8.32 8.32 0 00-3.94-1c-2.63 0-3.81 1-3.81 2.22 0 3.35 8.86 1.33 8.86 5.78 0 1.8-1.68 3.34-5.36 3.34a8.65 8.65 0 01-5.35-1.57zM36.85 48.67l-2.94 2.54v3.21h-1.52V42.19h1.52v7.39l8.44-7.39h1.75l-6.23 5.54 6.66 6.69h-1.76zM49.59 42.19h1.52v12.23h-1.52zM70.42 42.19v12.23h-1.26l-9.25-9.92v9.92h-1.52V42.19h1.26l9.27 9.93v-9.93zM76.42 48.31c0-3.58 3.21-6.22 7.54-6.22a7.66 7.66 0 015.27 1.78l-1 .82A6.26 6.26 0 0084 43.24c-3.48 0-6.09 2.17-6.09 5.07s2.59 5.06 6.09 5.06a6.21 6.21 0 004.25-1.46l1 .82a7.72 7.72 0 01-5.29 1.8c-4.33 0-7.54-2.64-7.54-6.22zM103.77 51.16h-8l-1.72 3.26H92.4L99 42.19h1.5l6.58 12.23h-1.6zm-.55-1l-3.45-6.57-3.45 6.57zM121.85 54.42l-3.29-3.93a11 11 0 01-1.15 0h-3.86v3.88H112V42.19h5.38c3.66 0 5.88 1.58 5.88 4.2 0 1.92-1.19 3.26-3.29 3.84l3.52 4.19zm-.08-8c0-2-1.52-3.08-4.4-3.08h-3.82v6.13h3.82c2.88 0 4.4-1.16 4.4-3.08zM139.66 53.3v1.12h-10.18V42.19h9.87v1.12H131v4.35h7.44v1.1H131v4.54z"
              />
            </g>
            <path
              stroke={props.scrolled ? colors.black : colors.white}
              id="divider"
              stroke-miterlimit="10"
              stroke-width="1"
              d="M10.2 34.45h135.03"
            />
            <g id="top_text" data-name="top text">
              <path
                fill={props.scrolled ? colors.black : colors.white}
                class="cls-3"
                d="M8.52 3.09H0V0h20.55v3.09H12v21.75H8.52zM28.88 0h3.55v24.84h-3.55zM62.39 21.76v3.08h-18V0h17.5v3.09h-14v7.63h12.47v3H47.91v8zM89.21 24.84l-5.36-7.63c-.49 0-1 .07-1.56.07h-6.14v7.56H72.6V0h9.69c6.46 0 10.37 3.27 10.37 8.66a7.83 7.83 0 01-5.47 7.84l5.89 8.34zm-.1-16.18c0-3.55-2.38-5.57-6.92-5.57h-6v11.18h6c4.54 0 6.92-2.06 6.92-5.61zM119.48 24.84l-5.36-7.63c-.49 0-1 .07-1.56.07h-6.14v7.56h-3.55V0h9.69c6.46 0 10.36 3.27 10.36 8.66a7.83 7.83 0 01-5.46 7.84l5.89 8.34zm-.11-16.18c0-3.55-2.37-5.57-6.92-5.57h-6v11.18h6c4.55 0 6.92-2.06 6.92-5.61zM149 18.63h-13.2l-2.8 6.21h-3.65L140.63 0h3.51l11.29 24.84h-3.73zm-1.24-2.84l-5.39-12.17L137 15.79z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Link>
  )
}

export default Logo
